import _ from "lodash";
import { nanoid } from "nanoid";
import {
  addQuestion,
  editQuestion,
  languageTranslation,
} from "redux/add-question/add-question-slice";
import { setSelectedQuestion } from "redux/manage-question/manage-question-slice";
import {
  fetchAllPassage,
  setPassageFlyoutMode,
  setSelectedPassage,
  setSelectedPassageId,
} from "redux/passage/passage-slice";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { addQuestionStrings } from "utils/constants/constants";
import {
  aes256Encrypt,
  convertBase64,
  convertData,
  encryptData,
  getStorageItem,
  notifyError,
  notifySuccess,
  removeHtmlContent,
} from "utils/utility";
import Compressor from "compressorjs";
import { successStatusCodes } from "utils/constants/common";

export const checkAllObjectValues = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== "") {
      return false;
    }
  }
  return true;
};

export const shouldAddNewTranslation = (
  languageTranslations,
  values,
  currentPassage
) => {
  let addNewTranslation = true;
  const index = languageTranslations?.length - 1;
  const details = languageTranslations?.[index]?.details;
  const passageDetail = languageTranslations?.[index]?.passageDetails;
  if (languageTranslations?.length > 0) {
    if (
      !languageTranslations?.[index]?.language ||
      (values.questionDescription && !details?.questionDescription) ||
      (values.questionImage?.value && !details?.questionImage?.value) ||
      (currentPassage?.passage && !passageDetail?.passage) ||
      (currentPassage?.image?.value && !passageDetail?.image?.value)
    )
      addNewTranslation = false;
    values?.answer?.map((ans, i) => {
      if (
        (ans && !details?.answerOptions?.[i]?.optionName) ||
        (values?.optionFile?.[i]?.value &&
          !details?.answerOptions[i]?.image?.value)
      )
        addNewTranslation = false;
    });
  }
  return addNewTranslation;
};

export const convertTranslationsToData = (languageTranslations, key) => {
  let paramsToSend = {};
  languageTranslations.map((lang) => {
    paramsToSend[lang["language"]] = lang[key];
  });
  return paramsToSend;
};

export const formatRequestData = (
  questionId,
  selectedInstanceData,
  values,
  subjectCategoriesList,
  subjectWiseCategoryList,
  levelsList,
  typesList,
  passageList,
  answerOptions,
  currentPassage = undefined
) => {
  let requestData = {
    questionId: questionId,
    instanceId: selectedInstanceData?._id,
    clientId: selectedInstanceData?.clientId,
    questionDescription: values?.questionDescription
      ? values?.questionDescription
      : undefined,
    questionImage: values?.questionImage ? values?.questionImage : undefined,
    language: values?.language,
    subject: convertData(
      subjectCategoriesList,
      "subjectName",
      "subjectCode",
      values.subject
    ),
    category: convertData(
      subjectWiseCategoryList,
      "categoryName",
      "categoryCode",
      values.category
    ),
    level: convertData(
      levelsList,
      "difficultyLevel",
      "difficultyCode",
      values.level
    ),
    type: convertData(typesList, "typeName", "typeCode", values?.type),
    marks: values?.marks,
    negativeMark: values?.negativeMark,
    duration: values?.duration,
    groupPassageId: convertData(
      passageList,
      "title",
      "_id",
      values?.groupQuestion
    ),
    isGroupPassage: values?.groupQuestion ? true : false,
    answerOptions: answerOptions?.map((option) => {
      option.optionName = option?.optionName;
      return option;
    }),
    groupPassage: currentPassage ? currentPassage : undefined,
  };
  if (values.type === "Text Box") {
    requestData.answerOptions = [
      {
        optionName: values.answer[0],
        correctValue: true,
        sequence: 1,
      },
    ];
  }
  return requestData;
};

export const handleOnAddTranslation = (
  languageTranslations,
  values,
  selectedLangArray,
  setSelectedLangArray,
  setLanguageTranslations,
  showTranslation = [],
  setShowTranslation,
  currentPassage
) => {
  const addNewTranslation = shouldAddNewTranslation(
    languageTranslations,
    values,
    currentPassage
  );
  if (addNewTranslation) {
    let translations = languageTranslations;
    translations.push({
      language: "",
      details: {
        questionDescription: "",
        questionImage: "",
        answerOptions: [],
      },
      passageDetails: {
        passage: "",
        image: "",
      },
    });
    const selectedLanguage =
      languageTranslations[languageTranslations?.length - 2]?.language;
    if (selectedLanguage && !selectedLangArray?.includes(selectedLanguage)) {
      setSelectedLangArray((prev) => [...prev, selectedLanguage]);
      selectedLangArray.push(selectedLanguage);
    }
    setLanguageTranslations([...translations]);
    setShowTranslation([...showTranslation, false]);
  } else {
    notifyError("Please complete translation for currently added language");
  }
};

export const callUpload = async (
  isLangTranslator,
  languageTranslations,
  setLanguageTranslations,
  values,
  setValues,
  e,
  key,
  index,
  languageIndex
) => {
  try {
    let width, height;
    const fileSelected = e;
    const reader = new FileReader();
    await reader.readAsDataURL(fileSelected);

    if (!fileSelected.name.match(/\.(jpg|png|jpeg)$/)) {
      notifyError("Please upload jpg, png or jpeg file");
      return;
    }

    reader.onload = function (e) {
      let image = new Image();
      image.src = e?.target?.result;

      image.onload = function async() {
        image.crossOrigin = "";
        width = image?.width;
        height = image?.height;
        if (width) {
          imageTest(height, width);
        }
        image.onload = null;
      };
    };
    function imageTest() {
      new Compressor(fileSelected, {
        quality: 0.8,
        success: (compressedResult) => {
          convertBase64(compressedResult).then((res) => {
            if (isLangTranslator) {
              const clonedLanguageTranslations =
                _.cloneDeep(languageTranslations);
              if (key === "optionFile") {
                if (
                  clonedLanguageTranslations[languageIndex]["details"][
                    "answerOptions"
                  ][index]
                ) {
                  clonedLanguageTranslations[languageIndex]["details"][
                    "answerOptions"
                  ][index]["image"] = { value: res, name: fileSelected?.name };
                } else {
                  const option = {
                    image: { value: res, name: fileSelected?.name },
                  };
                  clonedLanguageTranslations[languageIndex]["details"][
                    "answerOptions"
                  ] = [
                    ...clonedLanguageTranslations[languageIndex]["details"][
                      "answerOptions"
                    ],
                    option,
                  ];
                }
              } else if (key === "passage") {
                clonedLanguageTranslations[languageIndex]["passageDetails"][
                  "image"
                ] = { value: res, name: fileSelected?.name };
              } else {
                clonedLanguageTranslations[languageIndex]["details"][
                  "questionImage"
                ] = { value: res, name: fileSelected?.name };
              }
              setLanguageTranslations(clonedLanguageTranslations);
            } else {
              if (key === "optionFile") {
                const updatedOptionFile = _.cloneDeep(values?.optionFile);
                updatedOptionFile[index] = {
                  value: res,
                  name: fileSelected.name,
                };
                setValues((prevState) => ({
                  ...prevState,
                  optionFile: updatedOptionFile,
                }));
              } else {
                setValues((prevState) => ({
                  ...prevState,
                  questionImage: { value: res, name: fileSelected?.name },
                }));
              }
            }
          });
        },
      });
    }
  } catch (error) {
    notifyError(error);
  }
};

export const onSubmitLangTranslator = (
  languageTranslations,
  values,
  selectedId,
  selectedInstanceData,
  history,
  dispatch,
  selectedQuestion,
  currentPassage
) => {
  const addNewTranslation = shouldAddNewTranslation(
    languageTranslations,
    values,
    currentPassage
  );
  if (addNewTranslation) {
    if (
      Array.isArray(languageTranslations) &&
      languageTranslations?.length === 0
    ) {
      notifyError("Please Add at least one translation");
      return;
    }
    const params = convertTranslationsToData(languageTranslations, "details");
    const paramsToSend = {
      id: selectedId,
      data: {
        languageTranslate: params,
        instanceId: selectedInstanceData?._id,
      },
    };
    const passageParams = convertTranslationsToData(
      languageTranslations,
      "passageDetails"
    );
    if (selectedQuestion?.isGroupPassage) {
      paramsToSend["data"]["groupPassage"] = {
        groupPassageId: selectedQuestion?.groupPassageId,
        languageTranslate: passageParams,
      };
    }
    dispatch(languageTranslation(paramsToSend)).then((res) => {
      if (res?.payload?.statusCode === 200) {
        notifySuccess(res?.payload?.message);
        history.push(ROUTES_CONSTANTS?.translateQuestionsList);
      } else {
        notifyError(res?.payload?.data?.message);
      }
    });
  } else {
    notifyError("Please complete translation for currently added language");
  }
};

export const onSubmitNewQuestion = (
  multiCurrectOption,
  questionId,
  selectedInstanceData,
  values,
  subjectCategoriesList,
  subjectWiseCategoryList,
  levelsList,
  typesList,
  passageList,
  answerOptions,
  dispatch,
  history,
  manageQuestionFlyoutMode,
  selectedId,
  setLoading,
  selectedPassage = null
) => {
  let options = formatRequestData(
    questionId,
    selectedInstanceData,
    values,
    subjectCategoriesList,
    subjectWiseCategoryList,
    levelsList,
    typesList,
    passageList,
    answerOptions,
    selectedPassage
  );
  const publicKey = getStorageItem("publicKey");

  if (!options?.questionDescription && !options?.questionImage) {
    notifyError(addQuestionStrings.EITHER_ADD_QUESTION_OR_IMAGE);
    return;
  }

  if (values?.type === "Text Box") {
    if (!values.answer[0]) {
      notifyError("Please Add correct answer");
      return;
    }
  } else if (
    answerOptions?.filter(
      (option) => option?.optionName || option?.image?.value
    )?.length < 2
  ) {
    notifyError("Please add at least two options");
    return;
  }

  if (values?.type !== "Text Box") {
    let correctAnswerCount = 0;
    multiCurrectOption?.map((option) => {
      if (option) {
        correctAnswerCount++;
      }
    });
    if (correctAnswerCount === 0) {
      notifyError("Please Select correct answer");
      return;
    }
  }

  if (values?.optionType === "Text") {
    options.answerOptions = answerOptions?.map((option) => ({
      ...option,
      image: { value: "", name: "" },
    }));
  }

  if (manageQuestionFlyoutMode !== "Edit") {
    delete options["questionId"];
  }

  if (options["instanceId"] && options["instanceId"]?.endsWith("_ABC")) {
    delete options["instanceId"];
  }

  if (options["groupPassage"]) {
    delete options["groupPassage"];
  }

  if (values?.type === "Text Box") {
    options.answerOptions = [
      {
        optionName: removeHtmlContent(values?.answer?.[0]),
        correctValue: true,
        image: { value: "", name: "" },
        sequence: 1,
      },
    ];
  }

  const encryptKey = nanoid();
  const encryptIV = nanoid();
  const keys = {
    encryptKey: encryptKey,
    encryptIV: encryptIV,
  };
  const encryptedKeysData = encryptData(publicKey, JSON.stringify(keys));
  const allSchemaEncryptedData = aes256Encrypt(options, encryptKey, encryptIV);
  const dataToSend = {
    keys: encryptedKeysData,
    data: allSchemaEncryptedData,
  };

  const api =
    manageQuestionFlyoutMode === "Edit"
      ? editQuestion({
          id: selectedId,
          data: dataToSend,
        })
      : addQuestion(dataToSend);
  setLoading(true);
  dispatch(api)
    .then((res) => {
      if (successStatusCodes.includes(res?.payload?.statusCode)) {
        notifySuccess(res?.payload?.message);
        history.push(ROUTES_CONSTANTS?.questionListing);
      } else notifyError(res?.payload?.data?.message);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const validateTextLength = (e, length) => {
  const text = e?.getContent({ format: "text" });
  if (text?.length > length) {
    notifyError("Maximum length for answer allowed is " + length);
    return true;
  }
};

export const onChangeTranslatorEditor = (
  key,
  languageTranslations,
  setLanguageTranslations,
  e,
  index,
  languageIndex
) => {
  let clonedLanguageTranslations = _.cloneDeep(languageTranslations);
  const content = e?.getContent();
  const langDetails = clonedLanguageTranslations[languageIndex]?.details;
  if (clonedLanguageTranslations[languageIndex]) {
    if (key === "answer") {
      if (validateTextLength(e, 100)) {
        return;
      }
      if (
        Array.isArray(langDetails?.answerOptions) &&
        langDetails?.answerOptions[index]
      ) {
        clonedLanguageTranslations[languageIndex].details["answerOptions"][
          index
        ]["optionName"] = content;
      } else {
        const option = {
          optionName: content,
        };
        clonedLanguageTranslations[languageIndex].details["answerOptions"] = [
          ...langDetails["answerOptions"],
          option,
        ];
      }
    } else if (
      key === "passage" &&
      clonedLanguageTranslations[languageIndex]["passageDetails"]
    ) {
      clonedLanguageTranslations[languageIndex]["passageDetails"]["passage"] =
        content;
    } else {
      if (validateTextLength(e, 500)) {
        return;
      }
      clonedLanguageTranslations[languageIndex]["details"][
        "questionDescription"
      ] = content;
    }
    setLanguageTranslations(clonedLanguageTranslations);
  }
};

export const onChangeQuestionEditor = (key, e, values, setValues, index) => {
  const content = e?.getContent();
  if (key === "answer") {
    if (validateTextLength(e, 100)) {
      return;
    }
    const updatedAnswer = _.cloneDeep(values?.answer);
    updatedAnswer[index] = content;
    setValues((prev) => ({ ...prev, answer: updatedAnswer }));
  } else {
    if (validateTextLength(e, 500)) return;
    setValues((prev) => ({ ...prev, questionDescription: content }));
  }
};

export const resetValues = (setValues) => {
  setValues((prev) => ({
    ...prev,
    subject: "",
    category: "",
    type: "",
    level: "",
    marks: "",
    negativeMark: "",
    duration: "",
    language: "",
    groupQuestion: null,
    questionDescription: "",
    questionImage: {},
    optionType: "Text",
    answer: [],
    optionFile: [],
  }));
};

export const onlineFetchPassage = (
  dispatch,
  selectedQuestion,
  values,
  setValues
) => {
  dispatch(fetchAllPassage({ language: values?.language })).then((res) => {
    if (res?.payload?.statusCode !== 201) {
      notifyError(res?.payload?.data?.message);
    }
    if (selectedQuestion)
      setValues((prev) => ({
        ...prev,
        groupQuestion: convertData(
          res?.payload?.data,
          "_id",
          "title",
          selectedQuestion?.groupPassageId
        ),
      }));
  });
};

export const setAnswerOptionsValues = (
  values,
  multiCurrectOption,
  setAnswerOptions
) => {
  const updatedAnswerOptions =
    values.answer?.length > 0
      ? values.answer
          .map((_, index) => ({
            optionName: values?.answer[index],
            correctValue: multiCurrectOption[index],
            image: values?.optionFile[index],
            sequence: index + 1,
          }))
          .filter((option) => {
            return option?.optionName || option?.image;
          })
      : [];
  setAnswerOptions(updatedAnswerOptions);
};

export const onClickViewHandlerUtil = (dispatch, values, passageListing) => {
  if (!values?.language) {
    notifyError("Please select language first");
    return;
  } else if (!values?.groupQuestion) {
    notifyError("Please select passage to view");
    return;
  }
  if (
    Array.isArray(passageListing) &&
    passageListing?.length > 0 &&
    values?.groupQuestion
  ) {
    const passage = passageListing?.find(
      (passage) => passage?.title === values?.groupQuestion
    );
    if (passage) {
      dispatch(setPassageFlyoutMode("View"));
    } else {
      notifyError("Please select passage to view");
    }
    const passageId = passage?._id;
    dispatch(setSelectedPassage(passage));
    dispatch(setSelectedPassageId(passageId));
  } else {
    notifyError("Please select passage to view");
  }
};

export const onCancelRemoveTranslation = (
  setShowConfirmRemoveTranslation = () => {}
) => {
  setShowConfirmRemoveTranslation({
    state: false,
    language: null,
    languageTranslationIndex: null,
  });
};

export const redirectToAddUpdateQuestion = (
  val,
  id,
  data,
  pathName,
  history,
  dispatch
) => {
  redirectToDetail(val, id, data, pathName, history, dispatch);
};

const redirectToDetail = (val, id, data, pathname, history, dispatch) => {
  dispatch(setSelectedQuestion(data));
  history?.push({
    pathname: pathname,
    state: { mode: val, id: id, item: data },
  });
};
