import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveItemToStorage } from "utils/utility";

const firebaseConfig = {
  apiKey: "AIzaSyDgV2d2PyDSjIyiNQcCEjIqPQsBS7lOjZY",
  authDomain: "cbt-dev-local.firebaseapp.com",
  projectId: "cbt-dev-local",
  storageBucket: "cbt-dev-local.appspot.com",
  messagingSenderId: "324940682235",
  appId: "1:324940682235:web:579270f27a5402e66bf15e",
  measurementId: "G-673VZH15LK",
};

// Initialize Firebase
export const firebaseapp = initializeApp(firebaseConfig);
export const firebasemessaging = getMessaging(firebaseapp);

export const requestPermission = () => {
  Notification.requestPermission().then(async (permission) => {
    if (permission === "granted") {
      return getToken(firebasemessaging, {
        vapidKey: `BGkfuv652vdfVd8U2KnbsCtbhc3gySmuriqDp04oa2mmnqPZ--fs3fEKRdoENs8zwH8Oz6Pc1VhazwsOye60UoQ`,
      })
        .then((firebaseToken) => {
          if (firebaseToken) {
            saveItemToStorage("firebaseToken", firebaseToken);
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export const onMessageListener = (onNotification) => {
  onMessage(firebasemessaging, (payload) => {
    onNotification(payload);
  });
};
