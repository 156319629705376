import { API_URL } from "./Routes/api";

export const manageQuestionListingData = [
  {
    select: "checkBox",
    id: 1,
    version: 2.2,
    questions: "Main Question",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    multipleQuestions: [
      {
        id: 0,
        question:
          "question 1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        id: 1,
        question:
          "question 2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
      {
        id: 1,
        question:
          "question 3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
      },
    ],
    isChecked: false,
    groupQuestion: {
      id: 0,
      passage:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    questionType: "radio",
    answerType: "text",
    answers: [
      {
        id: 0,
        answer: "answer1",
        image: "",
        isCorrent: true,
      },
      {
        id: 1,
        answer: "answer2",
        image: "",
        isCorrent: false,
      },
      {
        id: 1,
        answer: "answer3",
        image: "",
        isCorrent: false,
      },
      {
        id: 1,
        answer: "answer4",
        image: "",
        isCorrent: false,
      },
    ],
  },
  {
    select: "checkBox",
    id: 2,
    version: 2.2,
    questions: "question",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    isChecked: false,
    questionType: "checkbox",
    answerType: "text",
    answers: [
      {
        id: 0,
        answer: "answer1",
        image: "",
        isCorrent: true,
      },
      {
        id: 1,
        answer: "answer2",
        image: "",
        isCorrent: false,
      },
      {
        id: 1,
        answer: "answer2",
        image: "",
        isCorrent: false,
      },
      {
        id: 1,
        answer: "answer2",
        image: "",
        isCorrent: false,
      },
    ],
  },
  {
    select: "checkBox",
    id: 1,
    version: 2.2,
    questions: "normal",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    isChecked: false,
    questionType: "dropdown",
  },
  {
    select: "checkBox",
    id: 1,
    version: 2.2,
    questions: "normal",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    isChecked: false,
    questionType: "multidropdown",
  },
  {
    select: "checkBox",
    id: 1,
    version: 2.2,
    questions: "normal",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    isChecked: false,
    questionType: "textfield",
  },
  {
    select: "checkBox",
    id: 1,
    version: 2.2,
    questions: "normal",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    isChecked: false,
  },
  {
    select: "checkBox",
    id: 1,
    version: 2.2,
    questions: "normal",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    isChecked: false,
  },
  {
    select: "checkBox",
    id: 1,
    version: 2.2,
    questions: "normal",
    language: "english",
    subject: "science",
    catagory: "obc",
    level: "intermediate",
    type: "manual",
    status: "open",
    options: "options",
    actions: "actions",
    isChecked: false,
  },
];

export const questionDetailsData = [
  {
    srNo: 123,
    version: "2",
    dateAndTime: "21-06-2021 02:55 AM",
    user: "intermediate",
    actionTaken: "SENT FOR REVIEW",
    questionStatus: "created",
  },
];

export const profileData = [
  {
    id: 1,
    name: "Amit",
    roles: {
      id: 1,
      roleName: "Author",
      isActive: true,
    },
  },
  {
    id: 2,
    name: "Mahesh",
    roles: {
      id: 2,
      roleName: "Question Reviewer",
      isActive: true,
    },
  },
  {
    id: 3,
    name: "Vansh",
    roles: {
      id: 3,
      roleName: "Language Translator",
      isActive: true,
    },
  },
  {
    id: 4,
    name: "Piyush",
    roles: {
      id: 4,
      roleName: "Language Reviewer",
      isActive: true,
    },
  },
  {
    id: 5,
    name: "Ansh",
    roles: {
      id: 5,
      roleName: "Approver",
      isActive: true,
    },
  },
];

export const roleTypeNameStrings = {
  USER_ROLE_AUTHOR: "Author",
  USER_ROLE_QUESTION_REVIEWER: "Question Reviewer",
  USER_ROLE_LAUNGUAGE_TRANSLATOR: "Language Translator",
  USER_ROLE_LAUNGUAGE_REVIEWER: "Language Reviewer",
  USER_ROLE_APPROVER: "Approver",
};

export const questionReviewerRoleList = [
  "Send to Language Translator",
  "Reject",
];

export const approverRoleList = ["Approve", "Reject"];

export const languageReviewerRoleList = ["Send to Publisher", "Reject"];

export const languageTranslatorRoleList = ["Send to Language Reviewer"];

export const authourRoleList = ["Send For Review"];

export const actionDropDownOptions = ["Profile", "Change Password", "Logout"];

export const numberOfEntries = [10, 20, 30, 40, 50, 100];

export const Strings = {
  INVALID_FILE_FORMAT: "Invalid File Format",
  ALPHA_NUMERIC_ERROR: "Only alphanumeric values allowed",
  DRAWER_TITLE: "What would you like to do:",
  FILE_FORMAT_PHOTO: "Only jpg, png or jpeg file is allowed",
  FILE_FORMAT_PHOTO_WITH_RESOLUTION:
    "Only jpg, png or jpeg file is allowed, suggested resolution Maximum 500px width and Maximum 250px height",
  LOGIN_ERR_NAME: "UserLambdaValidationException",
  LOGIN_ERR_MSG1: "User does not exist.",
  LOGIN_ERR_MSG2: "User is disabled.",
  INVALID_QPA_LOGIN: "This user does not belong with the QPA login.",
  INCORRECT_USERNAME_PASS: "Incorrect username or password.",
  USER_ACCOUNT_DEACTIVATED:
    "Your account has been deactivated. Please contact administrator.",
  FIELD_TITLE_DOB: "DATE OF BIRTH (DOB)",
  FIELD_TITLE_JOB: "Job Title",
  FIELD_PLACEHOLDER_JOB: "Job Title",
  FIELD_TITLE_LAST_NAME: "Last Name",
  FIELD_PLACEHOLDER_LAST_NAME: "Last Name",
  FIELD_TITLE_FIRST_NAME: "First Name",
  FIELD_PLACEHOLDER_FIRST_NAME: "First Name",
  IMAGE_DESCRIPTION:
    "Only jpg or png file is allowed, suggested resolution Min 600px width",
  FIELD_TITLE_ATTACH_PHOTO: "Attach Photo",
  PASSWORD_CONFIRMATION_TITLE: "Are you sure you want to change your password?",
  PASSOWORD_MISMATCH_TITLE: "Confirm Password mismatched",
  FIELD_PLACEHOLDER_CONFIRM_PWD: "Confirm password",
  FIELD_PLACEHOLDER_PWD: "Password",
  FIELD_PLACEHOLDER_NEW_PWD: "Enter new Password",
  FIELD_PLACEHOLDER_CURRENT_PWD: "Current password",
  FIELD_PLACEHOLDER_CONFIRAMTION_CODE: "Enter confirmation code",
  NAVBAR_TITLE_CHANGE_PWD: "Change Password",
  CHANGE_PWD_HEADING: "Change Password",
  CHANGE_PWD_DESCRIPTION:
    "Between 8 to 12 characters, using upper and lower case, including at least one number",
  LOGIN_LOADING_TITLE: "Logging in...",
  SUBMIT_LOADING_TITLE: "Submiting in...",
  DELETE_LOADING_TITLE: "Deleting",
  CONFIRMING_LOADING_TITLE: "Confirming",
  FIELD_PLACEHOLDER_FORGOT_EMAIL: "Email/Mobile",
  FORGOT_PWD_HEADING: "Forgot Password?",
  FORGOT_PWD_DESCRIPTION:
    "Please enter your email address. We will send the reset password [OTP] to your email",
  CONFIRMATION_MODE_PWD_MATCH: "Password and Confirm Password does not match",
  FIELD_PLACEHOLDER_EMAIL: "Email/Mobile",
  LOGIN_HEADING: "User Login",
  RESET_PWD_HEADING: "Reset Password",
  MODE_FORGOT_PWD: "forgot-password",
  MODE_NEW_PWD: "new-password",
  DELETE_PASSAGE_CONFIRMATION: "Are you sure you want to delete this Passage?",
  PIN_CHANGE_CONFIRMATION: "Are you sure you want to Change the pin?",
  PASSAGE_TITLE_TITLE: "Title",
  PASSAGE_TITLE_NO_OF_QUES: "NO Of Questions",
  PASSAGE_TITLE_PASSAGE: "Passage",
  FIELD_TITLE_CURRENT_PIN: "Enter current pin",
  FIELD_TITLE_NEW_PIN: "New Pin",
  FIELD_TITLE_ENTER_PIN: "Enter pin",
  FIELD_TITLE_RE_ENTER_PIN: "Re-enter Pin",
  FIELD_TITLE_AUTHOR_NAME: "Author Name",
  FIELD_TITLE_PLACEHOLDER_ALL: "All",
  FIELD_TITLE_SUBJECT: "SUBJECT",
  FIELD_TITLE_QP_LANGUAGE: "QP LANGUAGE",
  FIELD_TITLE_CATEGORY: "CATEGORY",
  FIELD_TITLE_LEVEL: "LEVEL",
  FIELD_TITLE_TYPE: "TYPE",
  FIELD_TITLE_TITLE: "Title",
  FIELD_TITLE_STATUS: "STATUS",
  FIELD_TITLE_PART_OF_GROUP_QUE: "Part of a group question?",
  FIELD_PLACEHOLDER_SELECT_SUBJECT: "Select Subject",
  FIELD_LABEL_SUBJECT: "Subject",
  FIELD_PLACEHOLDER_SELECT: "Select",
  FIELD_PLACEHOLDER_SELECT_CATEGORY: "Select Category",
  FIELD_LABEL_CATEGORY: "Category",
  FIELD_PLACEHOLDER_SELECT_TYPE: "Select Type",
  FIELD_LABEL_TYPE: "Type",
  FIELD_PLACEHOLDER_DIFFICULTY_LEVEL: "Select Difficulty Level",
  FIELD_LABEL_DIFFICULTY_LEVEL: "Difficulty Level",
  FIELD_LABEL_GROUP_QUESTION: "Group Question",
  FIELD_PLACEHOLDER_SELECT_LANGUAGE: "Select Language",
  FIELD_LABEL_LANGUAGE: "Language",
  FIELD_PLACEHOLDER_SELECT_PASSAGE: "Select Passage",
  FIELD_TITLE_ADD_PASSAGE_TRANSLATION: "Add Passage Translation",
  FIELD_PLACEHOLDER_MARKS: "Marks",
  FIELD_PLACEHOLDER_NEGATIVE_MARKING: "Negative Marking",
  FIELD_PLACEHOLDER_DURATION: "Duration",
  FIELD_PLACEHOLDER_PASSAGE_QUESTION: "Passage Question",
  FIELD_PLACEHOLDER_QUESTION_LABEL_TITLE: "Question Title/Label",
  FIELD_TITLE_ADD_CORRECT_ANS: "Add Correct Answer",
  FIELD_PLACEHOLDER_SELECT_QUE_TYPE: "Select Option Type",
  FIELD_LABEL_ANS_TYPE_QUE_TYPE: "Answer Type/ Option Type",
  FIELD_TITLE_SELECT_CORRECT_ANS: "Select Correct Answer",
  FLYOUT_HEADING_VIEW_PASSAGE: "View Passage",
  FIELD_TITLE_ATTACH_DOCUMENTS: "Attach Documents",
  FIELD_DESCRIPTION_ZIP_XLSX: "Only zip or xlsx files are allowed",
  LINK_TITLE: "Download Sample Excel Sheet",
  FLYOUT_HEADING_EDIT_USER_DETAILS: "Edit User Details",
  NAVBAR_TITLE_PROFILE: "Profile",
  NAVBAR_TITLE_QUESTION_DETAILS: "Question Details",
  CONFIRM_CLEAR_MSG: "Are you sure you want to clear the data?",
  CONFIRM_EXIT_MSG: "Are you sure you want to exit without saving the details?",
  CONFIRM_DELETE_MODE: "Are you sure you want to delete answer option?",
};

export const BtnTitles = {
  SAVE: "Save",
  CANCEL: "Cancel",
  CLEAR: "Clear",
  SUBMIT: "Submit",
  CONFIRM: "Confirm",
  BACK: "Back",
  DELETE: "Delete",
  EDIT: "Edit",
  APPLY: "APPLY",
  RESET: "RESET",
  RESET_SMALL: "Reset",
  ADD: "Add",
  PREVIEW: "Preview",
  ADD_QUESTION: "ADD QUESTION",
  ADD_ANSWER: "Add Answer",
  IMPORT_QUESTION: "IMPORT QUESTION",
  IMPORT: "Import",
  FORGOT_PWD: "Forgot Password",
  LOGIN: "Login",
  ADD_TRANSLATION: "Add Translation",
  CHANGE_PWD: "Change Password",
  CHANGE_PIN: "Change pin",
  CREATE_PIN: "Create Pin",
  VIEW_HISTORY: "View History",
};

export const FieldTypes = {
  TYPE_TEXT: "text",
  TYPE_NUMBER: "number",
  TYPE_EMAIL: "email",
  TYPE_PASSWORD: "password",
};

export const passageStrings = {
  ADD_EITHER_DETAIL_OR_PHOTO:
    "Please enter details or upload an image for passage to create a passage question!",
  WORD_LIMIT_EXCEEDED: "Word limit exceeded",
  NO_OF_QUESTIONS:
    "Number of questions must be a number less than or equal to 20",
};

export const addQuestionStrings = {
  EITHER_ADD_QUESTION_OR_IMAGE:
    "Please add either question description or an image",
};

export const importQuestionUrls = {
  UPLOAD_FILE_PREFIX: `${API_URL}/api/v1/file-storage/render/admin/`,
  SAMPLE_URL: "cbtexam-qa-uploadedfiles~Import%20Questions%20Reference.xlsx",
};
