import Modal from "components/widgets/modal/Modal";
import React, { useEffect, useState } from "react";
import "./QuestionAnswerPreviewModal.css";
import { DropDownField } from "components/widgets/drop-down-field/DropDownField";
import {
  IconCloseFlyout,
  IconSlideLeft,
  IconSlideRight,
} from "assets/svgs/Svgs";
import PreviewModalFooter from "./preview-modal-footer/PreviewModalFooter";
import Image from "components/widgets/image/Image";
import Loader from "components/widgets/loader/Loader";
import AnswerOptions from "components/widgets/answer-options/AnswerOptions";

const QuestionAnswerPreviewModal = ({
  isPreviewModalOpen,
  onChangePreviewVisibility,
  loader,
  previewQuestionList = [],
  onClickPreviewHandler,
  totalRecords = 1,
}) => {
  const [selectedDataIndex, setSelectedDataIndex] = useState(0);
  const [languageOptionsList, setLanguagesOptionsList] = useState([]);
  const [languageWiseData, setLanguageWiseData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const rejectedReason = previewQuestionList[selectedDataIndex]?.rejectedReason;
  const passageDetails = languageWiseData[selectedLanguage]?.passageDetails
    ? languageWiseData[selectedLanguage]?.passageDetails
    : {};

  useEffect(() => {
    setCount(count + 1);
    if (totalRecords > 1 && count > 1) {
      if (selectedDataIndex === 9) {
        setSelectedDataIndex(0);
      }
      if (selectedDataIndex === 0) {
        setSelectedDataIndex(9);
      }
    } else {
      setSelectedDataIndex(0);
    }
  }, [previewQuestionList]);

  useEffect(() => {
    if (selectedDataIndex) {
      setSelectedLanguage(previewQuestionList[selectedDataIndex]?.language);
    }
    let languageList = [];
    let languageWiseList = {};
    languageList.push(previewQuestionList[selectedDataIndex]?.language);
    languageWiseList[previewQuestionList[selectedDataIndex]?.language] = {
      questionDescription:
        previewQuestionList[selectedDataIndex]?.questionDescription,
      answerOptions: previewQuestionList[selectedDataIndex]?.answerOptions,
      type: previewQuestionList[selectedDataIndex]?.type,
    };
    if (previewQuestionList[selectedDataIndex]?.isGroupPassage) {
      languageWiseList[previewQuestionList[selectedDataIndex]?.language][
        "passageDetails"
      ] = {
        passage:
          previewQuestionList[selectedDataIndex]?.groupPassage?.passage || "",
        image:
          previewQuestionList[selectedDataIndex]?.groupPassage?.image?.value ||
          "",
      };
    }
    if (previewQuestionList[selectedDataIndex]?.languageTranslate) {
      Object.keys(
        previewQuestionList[selectedDataIndex]?.languageTranslate
      ).map((language) => {
        languageList.push(language);
        languageWiseList[language] = {
          questionDescription:
            previewQuestionList[selectedDataIndex]?.languageTranslate[language]
              ?.questionDescription,
          answerOptions:
            previewQuestionList[selectedDataIndex]?.languageTranslate[language]
              ?.answer ||
            previewQuestionList[selectedDataIndex]?.languageTranslate[language]
              ?.answerOptions,
          type: previewQuestionList[selectedDataIndex]?.type,
        };
        if (previewQuestionList[selectedDataIndex]?.isGroupPassage) {
          languageWiseList[language]["passageDetails"] = {
            passage:
              previewQuestionList[selectedDataIndex]?.groupPassage
                ?.languageTranslate[language]?.passage || "",
            image:
              previewQuestionList[selectedDataIndex]?.groupPassage
                ?.languageTranslate[language]?.image?.value || "",
          };
        }
      });
    }
    setLanguagesOptionsList(languageList);
    setLanguageWiseData(languageWiseList);
    setSelectedLanguage(previewQuestionList[selectedDataIndex]?.language);
  }, [previewQuestionList, selectedDataIndex]);

  const handleNextQuestion = () => {
    if (selectedDataIndex === 9) {
      setPage(page + 1);
      onClickPreviewHandler(page + 1);
    } else {
      setSelectedDataIndex(selectedDataIndex + 1);
    }
  };

  const handlePreviosQuestion = () => {
    if (
      previewQuestionList?.length > 0 &&
      selectedDataIndex === 0 &&
      page > 1
    ) {
      setPage(page - 1);
      onClickPreviewHandler(page - 1);
    } else {
      setSelectedDataIndex(selectedDataIndex - 1);
    }
  };

  const closeHandler = () => {
    setSelectedDataIndex(0);
    onChangePreviewVisibility(false);
  };

  const onlanguagechange = (e) => {
    if (e?.target?.value) {
      setSelectedLanguage(e?.target?.value);
    }
  };

  return (
    <Modal
      show={isPreviewModalOpen}
      modalContentClassName="preview_modal"
      closeHandler={closeHandler}
    >
      {loader ? (
        <Loader className="preview_modal_loader" />
      ) : (
        <>
          <div
            className={`d-flex flex-col  mb-4  ${
              rejectedReason
                ? "preview_modal_header_reject"
                : "preview_modal_header"
            }`}
          >
            <div className="d-flex flex-row justify-content-between">
              <DropDownField
                placeholder="Select language"
                className="preview_modal_dropdown"
                options={languageOptionsList}
                onChangeHandler={onlanguagechange}
                data={selectedLanguage}
              />
              <div onClick={() => closeHandler()}>
                <a className="close mr-2 cursor-pointer">
                  <IconCloseFlyout />
                </a>
              </div>
            </div>
            {rejectedReason ? (
              <div className="d-flex flex-col justify-content-start align-items-start reject_reason">
                <div>Reject Reason: </div>
                <div
                  className="text-overflow-multiple-line "
                  title={rejectedReason}
                >
                  {rejectedReason}
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={
              rejectedReason
                ? "preview_modal_container_footer_reject"
                : "preview_modal_container_footer"
            }
          >
            <div className="preview_modal_container ">
              <span className="your_question_span ">Your Question</span>
              <div className="preview_modal_content text-start px-3">
                {(selectedDataIndex != 0 || page > 1) && totalRecords !== 1 ? (
                  <span
                    className="left_arrow_icon"
                    onClick={() => handlePreviosQuestion()}
                  >
                    <IconSlideLeft />
                  </span>
                ) : null}
                {totalRecords - 1 === selectedDataIndex ||
                totalRecords === 1 ? null : (
                  <span
                    className="right_arrow_icon"
                    onClick={() => handleNextQuestion()}
                  >
                    <IconSlideRight />
                  </span>
                )}
                <div className="text-start my-4 mx-2">
                  {previewQuestionList &&
                  previewQuestionList.length > 0 &&
                  previewQuestionList[selectedDataIndex]?.isGroupPassage ? (
                    <>
                      {passageDetails?.passage ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: passageDetails?.passage,
                          }}
                          className="word-break-all"
                        ></span>
                      ) : null}
                      {passageDetails?.image ? (
                        <Image
                          url={passageDetails?.image}
                          urlOnly={true}
                          className="preview_modal_question_img"
                        />
                      ) : null}
                      <hr />
                    </>
                  ) : null}
                  <div>
                    <div
                      className="word-break-all"
                      dangerouslySetInnerHTML={{
                        __html:
                          languageWiseData[selectedLanguage]
                            ?.questionDescription,
                      }}
                    />
                    <div>
                      <Image
                        className="preview_modal_question_img"
                        url={
                          previewQuestionList[selectedDataIndex]?.questionImage
                            ?.value
                        }
                        urlOnly={true}
                      />
                    </div>

                    <AnswerOptions data={languageWiseData[selectedLanguage]} />
                  </div>
                </div>
              </div>
            </div>
            <PreviewModalFooter
              data={languageWiseData[selectedLanguage]}
              rejectedReason={rejectedReason}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default QuestionAnswerPreviewModal;
